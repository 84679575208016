<template>
    <component
        :is="external ? 'a' : Link"
        :class="[
            'relative text-2xl transition duration-300',
            `text-tenant-${globals.primary_nav.text_color?.value || 'black'} `,
            'hover:text-tenant-primary',
            current && 'text-tenant-primary',
            current && 'after:bg-tenant-primary after:absolute after:inset-x-0 after:-bottom-2 after:block after:h-1 after:select-none after:rounded',
        ]"
        :href="href"
    >
        <slot />
    </component>
</template>

<script lang="ts" setup>
    import { Link } from '@inertiajs/vue3';

    import { useSite } from '@/site/composables/use-site.ts';

    defineProps<{
        href: string,
        current?: boolean;
        external?: boolean;
    }>();

    const { globals } = useSite();

</script>
