<template>
    <!-- MOBILE NAV -->
    <nav
        :class="[
            'fixed inset-0 z-50 flex h-dvh w-screen flex-col overscroll-contain transition-all duration-300',
            mobileNavOpened ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0',
            `bg-tenant-${globals.primary_nav.background_color?.value || 'white'}`,
        ]"
    >
        <div
            :class="[
                'container flex h-18 items-center',
                logo ? 'justify-between' : 'justify-end',
            ]"
        >
            <component
                :is="logoExternalHref ? 'a' : Link"
                v-if="logo"
                :href="homeHref"
            >
                <img
                    :src="logo"
                    alt="Logo"
                    class="block max-h-12 max-w-48 md:max-h-18"
                >
            </component>
            <button @click="triggerMobileNav">
                <AspectIcon
                    :class="[
                        'size-6',
                        `text-tenant-${globals.primary_nav.text_color?.value || 'black'}`,
                    ]"
                    name="delete-1"
                />
            </button>
        </div>

        <div class="flex grow flex-col items-stretch justify-between p-4">
            <div class="flex grow flex-col items-center gap-8 text-center">
                <slot name="mobile">
                    <SiteMobileNavItem
                        v-for="navItem in navs.main_nav"
                        :key="navItem.id"
                        :current="isCurrent(navItem)"
                        :external="navItem.is_external"
                        :href="navItem.url"
                    >
                        {{ navItem.title }}
                    </SiteMobileNavItem>
                </slot>
                <button
                    v-if="showLocaleSwitcher"
                    :class="[
                        'text-2xl transition duration-300',
                        `text-tenant-${globals.primary_nav.text_color?.value || 'black'}`,
                    ]"
                    @click="swapLocale"
                >
                    {{ locale }}
                </button>
            </div>

            <div class="flex shrink-0 gap-4">
                <SiteButton
                    v-if="globals?.primary_nav_action.button && globals?.primary_nav_action.button_text"
                    :href="linkTypeHref"
                    :is-entry="globals.primary_nav_action.button_link?.startsWith('/')"
                    :target="globals.primary_nav_action.button_target ? '_blank' : '_self'"
                    :text="globals.primary_nav_action.button_text"
                    :tracking-id="globals.primary_nav_action.button_tracking_id"
                    :bg-color="globals.primary_nav_action.button_background_color?.value || 'primary'"
                    :text-color="globals.primary_nav_action.button_text_color?.value || 'white'"
                    class="grow"
                />
            </div>
        </div>
    </nav>

    <!-- DESKTOP NAV -->
    <nav
        :class="[
            'inset-x-0 top-0 z-20 h-18 items-center shadow-lg md:h-24',
            globals.primary_nav.nav ? 'flex' : 'hidden',
            globals.primary_nav.fixed ? 'sticky' : 'static',
            `bg-tenant-${globals.primary_nav.background_color?.value || 'white'}`,
        ]"
    >
        <div class="container flex items-center justify-between">
            <div
                :class="[
                    'flex grow items-center',
                    navs?.main_nav.length > 5 ? 'gap-6' : 'gap-6 lg:gap-12',
                ]"
            >
                <component
                    :is="logoExternalHref ? 'a' : Link"
                    v-if="logo"
                    :href="homeHref"
                >
                    <img
                        :src="logo"
                        alt="Logo"
                        class="block max-h-12 max-w-48 md:max-h-18"
                    >
                </component>
                <slot>
                    <SiteNavItem
                        v-for="navItem in navs.main_nav"
                        :key="navItem.id"
                        :current="isCurrent(navItem)"
                        :external="navItem.is_external"
                        :href="navItem.url"
                    >
                        {{ navItem.title }}
                    </SiteNavItem>
                </slot>
            </div>

            <div class="flex shrink-0 items-center justify-end gap-12 py-3">
                <button
                    v-if="showLocaleSwitcher"
                    class="hidden transition duration-300 hover:text-tenant-500 md:block"
                    @click="swapLocale"
                >
                    {{ locale }}
                </button>
                <SiteButton
                    v-if="globals?.primary_nav_action.button && globals?.primary_nav_action.button_text"
                    :href="linkTypeHref"
                    :is-entry="globals.primary_nav_action.button_link?.startsWith('/')"
                    :target="globals.primary_nav_action.button_target ? '_blank' : '_self'"
                    :text="globals.primary_nav_action.button_text"
                    :tracking-id="globals.primary_nav_action.button_tracking_id"
                    :bg-color="globals.primary_nav_action.button_background_color?.value || 'primary'"
                    :text-color="globals.primary_nav_action.button_text_color?.value || 'white'"
                    class="hidden md:block"
                />
                <button
                    class="block md:hidden"
                    @click="triggerMobileNav"
                >
                    <AspectIcon
                        :class="[
                            'size-6', `text-tenant-${globals.primary_nav.text_color?.value || 'black'}`,
                        ]"
                        name="hamburger-menu-1"
                    />
                </button>
            </div>
        </div>
    </nav>
</template>

<script lang="ts" setup>
    import { Link } from '@inertiajs/vue3';
    import { useSite } from '@/site/composables/use-site.ts';
    import { currentLocale, setLocale } from '@aspect/shared/plugins/i18n.ts';
    import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    import SiteButton from '@/site/components/site-button.vue';
    import SiteNavItem from '@/site/components/site-nav-item.vue';
    import SiteMobileNavItem from '@/site/components/site-mobile-nav-item.vue';

    const props = withDefaults(defineProps<{
        logoSrc?: string;
        logoHref?: string;
        logoExternalHref?: boolean;
        showLocaleSwitcher?: boolean;
    }>(), {
        logoExternalHref: false,
        showLocaleSwitcher: false,
    });

    const pageProps = usePageProps();

    const { navs, globals } = useSite();

    const nav = computed(() => {
        return navs.value?.main_nav;
    });

    const mobileNavOpened = ref(false);

    const locale = computed(() => {
        return {
            en: 'Français',
            fr: 'English',
        }[currentLocale.value];
    });

    const homeHref = computed<string>(() => {
        return props.logoHref || nav.value && nav.value[0]?.url;
    });

    const logo = computed<string | null>(() => {
        return props.logoSrc || pageProps.value.tenant.logo?.url || null;
    });

    function triggerMobileNav() {
        mobileNavOpened.value = !mobileNavOpened.value;
    }

    function swapLocale() {
        if (currentLocale.value === 'en') {
            setLocale('fr');
        } else {
            setLocale('en');
        }
    }

    function isCurrent(navItem) {
        return window.location.pathname === navItem.url;
    }

    const linkTypeHref = computed(() => {
        const action = globals.value.primary_nav_action;

        // Hides link if button is disabled
        if (action.button_disabled) {
            return;
        }

        // Files
        if (action.button_link_type.value === 'file') {
            return action.button_file.url;
        }

        // Email
        if (action.button_link_type.value === 'email' && action.button_email_address) {
            return action.button_full_name ? `mailto:${action.button_full_name}<${action.button_email_address}>` : `mailto:${action.button_email_address}`;
        }

        // Phone
        if (action.button_link_type.value === 'phone' && action.button_phone_number) {
            return `tel:${action.button_phone_number}`;
        }

        // Webpages
        if (action.button_link_type.value === 'webpage') {
            return `${action.button_link || ''}${action.button_fragment_identifier ? `#${action.button_fragment_identifier}` : ''}`;
        }

        return '';
    });
</script>
