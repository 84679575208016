<template>
    <SiteHead
        :tenant="tenant"
        :page="page"
    />

    <div v-if="globals.site_wide_banner.active && globals.site_wide_banner.position.value === 'top'">
        <SiteWideBanner />
    </div>

    <SiteNav />

    <SiteHeader v-if="page.header_active" :page="page" />

    <SiteSections :page="page" />

    <div v-if="globals.site_wide_banner.active && globals.site_wide_banner.position.value === 'bottom'">
        <SiteWideBanner />
    </div>

    <SiteFooter />
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    import SiteWideBanner from '@/site/components/site-wide-banner.vue';
    import SiteNav from '@/site/components/site-nav.vue';
    import SiteFooter from '@/site/components/site-footer.vue';
    import SiteHead from '@/site/components/site-head.vue';
    import SiteSections from '@/site/components/site-sections.vue';
    import SiteHeader from '@/site/components/site-header.vue';
    import type { TenantData } from '@aspect/shared/types/generated';

    defineOptions({
        inheritAttrs: false,
    });

    defineProps<{
        tenant: TenantData;
        page: {
            sections: any[];
            [x: string]: any;
        };
    }>();

    const { globals } = useSite();
</script>
