<template>
    <header
        :id="page.header_section_id"
        :class="[
            'flex flex-col justify-center',

            // Height management
            globals.primary_nav.fixed && 'pt-18 md:pt-24',
            !globals.primary_nav.fixed && '-mt-18 pt-18 md:-mt-24 md:pt-24',
            classes.sectionMinimumHeight[page.header_section_minimum_height.value] || 'min-h-auto',

            // Background
            `bg-tenant-${page.header_background_color?.value || 'white'}`,
            page.header_background_image && 'bg-cover bg-no-repeat',
            classes.bgPosition[page.header_background_image_position.value],
        ]"
        :style="[
            page.header_background_image && `background-image: linear-gradient(to right, rgba(${backgroundOverlay}), rgba(${backgroundOverlay})), url(${page.header_background_image.url});`,
        ]"
    >
        <div
            :class="[
                'container grid grid-cols-1',
                classes.sectionPadding[globals.styles.spacing.value],
                classes.contentGap[globals.styles.spacing.value]
            ]"
        >
            <div
                :class="[
                    'flex flex-col',
                    classes.contentGapHalf[globals.styles.spacing.value]
                ]"
            >
                <h1
                    v-if="page.header_title"
                    :class="[
                        'font-title text-4xl',
                        classes.textAlignment[page.header_title_alignment.value],
                        classes.fontWeight[globals.fonts?.title_font_weight] || 'font-normal',
                        classes.textTransform[globals.fonts?.title_text_transform] || 'normal-case',
                        classes.letterSpacing[globals.fonts?.title_letter_spacing] || 'tracking-normal',
                        `text-tenant-${page.header_title_text_color?.value || titleColor}`,
                    ]"
                >
                    {{ page.header_title }}
                </h1>
                <h2
                    v-if="page.header_sub_title"
                    :class="[
                        'font-subtitle text-2xl',
                        classes.textAlignment[page.header_title_alignment.value],
                        classes.fontWeight[globals.fonts?.subtitle_font_weight] || 'font-normal',
                        classes.textTransform[globals.fonts?.subtitle_text_transform] || 'normal-case',
                        classes.letterSpacing[globals.fonts?.subtitle_letter_spacing] || 'tracking-normal',
                        `text-tenant-${page.header_subtitle_text_color?.value || subtitleColor}`,
                    ]"
                >
                    {{ page.header_sub_title }}
                </h2>
            </div>

            <div
                v-if="page.header_ctas.length"
                :class="[
                    'flex items-center',
                    classes.contentGapHalf[globals.styles.spacing.value],
                    ctaAlignement,
                ]"
            >
                <SiteButton
                    v-for="(cta, index) in page.header_ctas"
                    :key="index"
                    :href="linkTypeHref(cta)"
                    :is-entry="cta.button_link?.startsWith('/')"
                    :target="cta.button_target ? '_blank' : '_self'"
                    :text="cta.button_text"
                    :tracking-id="cta.button_tracking_id"
                    :bg-color="cta.button_background_color?.value || 'primary'"
                    :text-color="cta.button_text_color?.value || 'white'"
                />
            </div>
        </div>
    </header>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';
    import SiteButton from '@/site/components/site-button.vue';

    const props = defineProps<{
        page: any;
    }>();

    const { classes, globals, getBackgroundOverlay } = useSite();

    const titleColor = computed(() => {
        return backgroundOverlayColor?.value === 'darken' ? 'white' : 'black';
    });

    const subtitleColor = computed(() => {
        return backgroundOverlayColor?.value === 'darken' ? 'white' : 'black';
    });

    const backgroundOverlayColor = computed(() => {
        return props.page.header_background_image ? props.page.header_background_overlay_color?.value : undefined;
    });

    const backgroundOverlay = computed(() => {
        return getBackgroundOverlay(props.page.header_background_overlay_color.value, props.page.header_background_overlay_opacity);
    });

    // const heightClasses = computed(() => {
    //     if (globals.value.primary_nav.nav) {
    //         return {
    //             // If the nav is fixed, the height of the hero section is adjusted to account for the nav.
    //             'min-h-[calc(100dvh_-_4.5rem)] md:min-h-[calc(100dvh_-_6rem)]': globals.value.primary_nav.fixed,

    //             // If the nav is not fixed, the height of the hero section is set to 100vh except for the first one, if it's the first child.
    //             'first-of-type:min-h-[calc(100dvh_-_4.5rem)] md:first-of-type:min-h-[calc(100dvh_-_6rem)] min-h-dvh': !globals.value.primary_nav.fixed,
    //         };
    //     }

    //     // If there is no nav, the height of the hero section is set to 100vh.
    //     return 'min-h-dvh';
    // });

    const ctaAlignement = computed(() => {
        return {
            left: 'justify-start',
            center: 'justify-center',
            right: 'justify-end',
        }[props.page.header_title_alignment.value];
    });

    const linkTypeHref = (cta) => {
        // Hides link if button is disabled
        if (cta.button_disabled) {
            return;
        }

        // Files
        if (cta.button_link_type.value === 'file') {
            return cta.button_file.url;
        }

        // Email
        if (cta.button_link_type.value === 'email' && cta.button_email_address) {
            return cta.button_full_name ? `mailto:${cta.button_full_name}<${cta.button_email_address}>` : `mailto:${cta.button_email_address}`;
        }

        // Phone
        if (cta.button_link_type.value === 'phone' && cta.button_phone_number) {
            return `tel:${cta.button_phone_number}`;
        }

        // Webpages
        if (cta.button_link_type.value === 'webpage') {
            return `${cta.button_link || ''}${cta.button_fragment_identifier ? `#${cta.button_fragment_identifier}` : ''}`;
        }
    };
</script>
